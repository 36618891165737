import React from "react"
import useTranslations from "../../../components/useTranslations"
import Footer from "../../../components/Footer/Footer";
import SupportLeftNav from "../../../components/TwoColumnLeftNav/SupportLeftNav";
import LocalizedLink from "../../../components/localizedLink";
import {LocaleContext} from "../../../components/layout";

const SoftDownload = () => {
    // useTranslations is aware of the global context (and therefore also "locale")
    // so it'll automatically give back the right translations
    const { softDownload } = useTranslations()
    const { locale } = React.useContext(LocaleContext)
    const SoftDownloadJa = () => {
        return(
            <ul className="software-ul flex wrap">
                <li className="software-li ">
                    <LocalizedLink className={'medium'} to={`/support/software-download/thermometer`} >防水型無線温度計 SK-270WP-B用</LocalizedLink>
                </li>
                <li className="software-li">
                    <LocalizedLink className={'medium'} to={`/support/software-download/kioku73j`} >記憶計 SK-L750シリーズ用</LocalizedLink>
                </li>
                <li className="software-li">
                    <LocalizedLink className={'medium'} to={`/support/software-download/sk-musen`} >無線温度・温湿度ロガー SK-L700Rシリーズ用</LocalizedLink>
                </li>
                <li className="software-li">
                    <LocalizedLink className={'medium'} to={`/support/software-download/barometer`} >デジタル気圧計 SK-500B用</LocalizedLink>
                </li>
                <li className="software-li">
                    <LocalizedLink className={'medium'} to={`/support/software-download/four-chLogger`} >４ｃｈ温度ロガー SK-L400T用</LocalizedLink>
                </li>
                <li className="software-li">
                    <LocalizedLink className={'medium'} to={`/support/software-download/kioku62j`} >記憶計 SK-L200Ⅱシリーズ用</LocalizedLink>
                </li>
                <li className="software-li">
                    <LocalizedLink className={'medium'} to={`/support/software-download/two-chLogger`} >2チャンネル温度ロガー SK-L210T用</LocalizedLink>
                </li>
                <li className="software-li">
                    <LocalizedLink className={'medium'} to={`/support/software-download/psychrometer`} >通風乾湿計による湿度計算シート</LocalizedLink>
                </li>
            </ul>
        )
    }
    const SoftDownloadEn = () => {
        return(
            <ul className="software-ul flex wrap">
                <li className="software-li">
                    <LocalizedLink className={'medium'} to={`/support/software-download/kioku73j`} >Software for SK-L750 Series Dataloggers</LocalizedLink>
                </li>
                <li className="software-li">
                    <LocalizedLink className={'medium'} to={`/support/software-download/barometer`} >Software for Digital Barometer Model SK-500B</LocalizedLink>
                </li>
                <li className="software-li">
                    <LocalizedLink className={'medium'} to={`/support/software-download/four-chLogger`} >Software for 4-ch Datalogger Model SK-L400T</LocalizedLink>
                </li>
                <li className="software-li">
                    <LocalizedLink className={'medium'} to={`/support/software-download/kioku62j`} >Software for SK-L200 and SK-L200II Series Dataloggers</LocalizedLink>
                </li>
                <li className="software-li">
                    <LocalizedLink className={'medium'} to={`/support/software-download/psychrometer`} >Aspirated Psychrometer Calculation Sheet</LocalizedLink>
                </li>
            </ul>
        )
    }
    return (
        <>
            <article className="company-info two-column-left-nav">
                <SupportLeftNav/>
                <section className="main-content">
                    <h2 className={'large bold'}>{softDownload}</h2>
                    <div className="content-container">
                        {locale === 'ja'?
                            <SoftDownloadJa/>:
                            <SoftDownloadEn/>
                        }
                    </div>
                </section>
            </article>
            <Footer/>
        </>
    )
}

export default SoftDownload
